.longread-article {

}

.longread-article.color-white {
    background: white;
}

.longread-article.color-black {
    background: black;
}

.longread-article .introduction {
    width: 100vw;
    height: 100vh;
}

.longread-article nav {
    position: fixed;
    top: 6.5vh;
    left: 0;
    bottom: 6.5vh;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (min-width: 320px) {
        left: 0
    }
    @media only screen and (min-width: 1024px) {
        left: calc(50vw - 350px - 3rem);
    }

}

.longread-article nav h3 {
    position: absolute;
    bottom: 16vh;
    font-size: 0.9rem;
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    font-weight: normal;
    transform: scale(-1, -1);
    width: 1rem;
    overflow: hidden;
}

.longread-article .body {
    color: black;
    margin-left: 2.7rem;
    padding-bottom: 3rem;
}


.longread-article h1 {
    font-family: "ReplicaLLWeb-Bold";
    font-size: 2.2rem;
    font-weight: normal;
    margin-top: 5rem;
    margin-bottom: 1.7rem;
    @media only screen and (min-width: 1024px) {
        font-size: 2.2rem;
    }
    @media only screen and (min-width: 1280px) {
        font-size: 2.9rem;
    }
}

.longread-article .author {
    font-size: 1.14rem;
    font-weight: normal;
}

.longread-article h3,
.longread-article h2 {
    margin: 0;
    padding: 0;
    color: black;
}

.longread-article .heading a {
    text-decoration: none;
}

.longread-article .heading h2,
.longread-article .heading h3 {
    font-weight: normal;
    color: white;
}

.longread-article .publication-title {
    position: static;
    left: 0;
}

.longread-article .publication-title h2 {
    font-weight: 800;
}

/* .longread-article .body {
    font-family: "ReplicaLLWeb-Bold";
    font-size: 1.14rem;
    width: 85%;
} */


.longread-article .patty {
    position: absolute;
    bottom: 0;
}

.longread-article .nav-icon {
    width: 1.8rem;
    height: 1.8rem;
}