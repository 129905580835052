/* complete */

@font-face {
  font-family: "ReplicaLLWeb-Regular";
  src: url("/public/fonts/ReplicaLLWeb-Regular.woff") format("woff");
}

@font-face {
  font-family: "ReplicaLLWeb-Regular";
  src: url("/public/fonts/ReplicaLLWeb-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "ReplicaLLWeb-Light";
  src: url("/public/fonts/ReplicaLLWeb-Light.woff") format("woff");
}

@font-face {
  font-family: "ReplicaLLWeb-Light";
  src: url("/public/fonts/ReplicaLLWeb-Light.woff2") format("woff2");
}

@font-face {
  font-family: "ReplicaLLWeb-Bold";
  src: url("/public/fonts/ReplicaLLWeb-Bold.woff") format("woff");
}

@font-face {
  font-family: "ReplicaLLWeb-Bold";
  src: url("/public/fonts/ReplicaLLWeb-Bold.woff2") format("woff2");
}


/* subset */

@font-face {
  font-family: "ReplicaLLSub-Regular";
  src: url("/public/fonts/ReplicaLLSub-Regular.woff") format("woff");
}

@font-face {
  font-family: "ReplicaLLSub-Regular";
  src: url("/public/fonts/ReplicaLLSub-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "ReplicaLLSub-Light";
  src: url("/public/fonts/ReplicaLLSub-Light.woff") format("woff");
}

@font-face {
  font-family: "ReplicaLLSub-Light";
  src: url("/public/fonts/ReplicaLLSub-Light.woff2") format("woff2");
}

@font-face {
  font-family: "ReplicaLLSub-Bold";
  src: url("/public/fonts/ReplicaLLSub-Bold.woff") format("woff");
}

@font-face {
  font-family: "ReplicaLLSub-Bold";
  src: url("/public/fonts/ReplicaLLSub-Bold.woff2") format("woff2");
}



/* complete */

@font-face {
  font-family: "ReplicaMonoLLWeb-Regular";
  src: url("/public/fonts/ReplicaMonoLLWeb-Regular.woff") format("woff");
}

@font-face {
  font-family: "ReplicaMonoLLWeb-Regular";
  src: url("/public/fonts/ReplicaMonoLLWeb-Regular.woff2") format("woff2");
}


/* subset */

@font-face {
  font-family: "ReplicaMonoLLSub-Regular";
  src: url("/public/fonts/ReplicaMonoLLSub-Regular.woff") format("woff");
}

@font-face {
  font-family: "ReplicaMonoLLSub-Regular";
  src: url("/public/fonts/ReplicaMonoLLSub-Regular.woff2") format("woff2");
}

html,body {
  height: 100%;
}

body {
  /* background-color: rgb(50,50,50); */
  font-family: "ReplicaLLWeb-Regular";
  color: white;
}


body {
	background: radial-gradient(rgb(30,30,30),rgb(50,50,50),rgb(30,20,30),rgb(25,30,25),rgb(40,40,40));
	background-size: 400% 400%;
	animation: gradient 40s ease infinite;
	height: 100vh;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}




@media (prefers-reduced-motion: no-preference) {
  .logo-layer.forward {
    animation: forward-rotation infinite 20s linear;
  }
  .logo-layer.backward {
    animation: backward-rotation infinite 40s linear;
  }
}

@keyframes forward-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes backward-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.logo-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.logo-layer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-layer svg {
  transform: scale(2.3);
}








.caption-index {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100dvw;
  height: 100dvh;
}

.caption-index a,
.caption-detail a {
  font-size: 1rem;
  text-decoration: none;
}

.vertical-title {
    font-size: 0.8rem;
}

.vertical-title h1 {
  margin: 0;
  padding: 0;
  color: rgb(136,137,136);
  text-align: center;
  font-weight: normal;
}
.vertical-title h1 span {
  font-family: "ReplicaMonoLLSub-Regular";
  display: block;
  margin-bottom: 0.4rem;
}

.vertical-title h1 span:last-child {
  margin: 0;
}


.caption-detail {
  width: 100dvw;
  height: 100dvh;
}

.caption-detail .vertical-title {
  transform: rotate(180deg);
  left: 1.2rem;
  position: fixed;
  top: 0;
  height: 100lvh;
  display: flex;
  align-items: center;
  z-index: 0;
}

.caption-detail h1.artist {
  font-size: 2.3rem;
  letter-spacing: 0.04rem;
  font-weight: 500;
  margin-top: 6.3vh;
  margin-bottom: 1.4rem;
}

.caption-detail article {
  font-size: 1.25rem;
  margin-top: 1.7rem;
  line-height: 1.235;
  margin-right: 2rem;
}

.caption-detail .container {
  margin-left: 3.5rem;
}

.work-titles {
  margin-left: 0.8rem;
}

.work-properties .technique {
  font-style: italic;
}

.work-properties {
  font-size: 1.25rem;
  margin-bottom: 1.6rem;
}

.caption-detail .nav-icon {
  border-radius: 50%;
  background-color: rgb(230,230,230);
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 3rem;
  right: 2.2rem;
}

.captions {
  max-width: 600px;
}



.nav-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
}

/* .nav-icon svg {
    width: 50px;
    height: 50px;
} */

.nav-icon svg path.st0 {
  fill: black;
}



.index-title {
  position: fixed;
  bottom :0;
  left: 0;
  right: 0;
  height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
  font-weight: normal;
  font-size: .9rem;
}


.publication-title {

}

.publication-title h2,
.publication-title h3 {
  font-weight: normal;
  font-size: 0.8rem;
  padding: 0;
  margin: 0;
  color: rgb(174,174,174);
}

.publication-title h2 {
  font-weight: 800;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  letter-spacing: 0.05rem;
}


.article-nav {
  width: 100vw;
  position: absolute;
  top: 13vh;
  height: 61vh;
}

.article-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  display: flex;
}

.article-nav ul li {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 65%;
  display: block;
  margin-left: 1.6vw;
  margin-right: 1.6vw;
}

.article-nav ul li.large {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.article-nav ul li a {
  color: black;
  text-decoration: none;
}

.article-nav ul li .container {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  overflow: hidden;
  box-sizing: border-box;
}

.article-nav ul li.large .container {
  border-radius: 0.5rem;
}

.article-nav ul li .container.text {
  font-size: 1.7rem;
  font-family: "ReplicaMonoLLWeb-Regular";
  padding: 0.7rem;
  padding-top: 2.1rem;
  box-sizing: border-box;
}

.article-nav ul li .container.question {
  border: 0;
}

.article-nav ul li .container.text .text {
  margin-bottom: 2rem;
}

.article-nav ul li .container.text .author,
.article-nav ul li .container.text .year {
  font-size: 1.1rem;
}

.article-nav ul li:first-child {
  margin-left: 100vw;
}

.longread-index {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.longread-index .publication-title {
  color: white;
}



.controls {
  position: relative;
  margin-top: 1.8rem;
  color: black;
  width: 50vw;
  left: 25vw;
}

.controls .border {
  position: absolute;
  width: 50vw;
  border-bottom: 2px solid white;
  box-sizing: border-box;
  z-index: 0;
}

.controls .position {
  position: relative;
  font-size: 2rem;
  opacity: 0;
}

.controls .position .bullet {
  border-radius: 50%;
  background: black;
  border: 1px solid white;
  width: 0.5rem;
  height: 0.5rem;
  margin: 0 auto;
}



.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}



input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
}


input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: white;
  border-radius: 1.3px;
  /* border: 0.2px solid #010101; */
}
input[type=range]::-webkit-slider-thumb {
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
  /* border: 1px solid #000000; */
  height: 12px;
  width: 12px;
  border-radius: 10px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
  background: black;
  border: 1px solid white;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: white;
  height: 2px;
}
input[type=range]::-moz-range-track {
  width: 100%;
  /* height: 8.4px; */
  cursor: pointer;
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
  /* background: #3071a9; */
  background: white;
  /* border-radius: 1.3px; */
  /* border: 0.2px solid #010101; */
}
input[type=range]::-moz-range-thumb {
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
  border: 1px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 10px;
  background: #ffffff;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
  background: #3071a9;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #3071a9;
}
input[type=range]:focus::-ms-fill-upper {
  background: #367ebd;
}

.page-colofon article p:first-child {
  padding-top: 6.5dvh;
}

.page-colofon article > div p {
  width: 50%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}